<template>
  <div class="container bg-gray-200 mx-auto w-full h-full mt-10">
    <p>
      And there was slain Medusa. Perseus took with him the head, but the body left he to rot. 
      Along his travels, Perseus carried the head of the creature. Blood, it dripped, trailing 
      across the ground the length of the journey. No one thought of it twice. It was merely the 
      gorey victory to which Perseus and his party were accustomed.
    </p>

    <br>

    <p>
      The blood stayed where it fell. After a time, the dripping ceased, Medusa's head drained 
      at long last of its lifeblood. The Epics followed Perseus, but Fate remained with the blood.
    </p>

    <br>

    <p>
      As Fate remained, so it evolved. The slick magick of Athena did carry itself into the Earth where
      fallen was the blood. Seep and bury, it did, this magick of the gods. The vengeance of Athena
      is not so easily reconciled as with the passing of one life, and, thus, from the clay and the iron
      of the Gorgon's lifeblood, Ten Hundred and Forty spawn.
    </p>

    <p>
      These spawn were not unlike their predecessor, hungry and loose. While Medusa's hatred was
      bourne of sadness, the Ire of the Gorgons was generational and tidal, coming and going in white
      waves of rage. When swept up by these waves, the Gorgons would prove sharp and deadly.
    </p>

    <br>

    <p>
      The Gorgons were many in form. No one alike to its sister. The scaled carcass their most uniform trait.
      Though lacking in any tip of hive-mind, the instinct of the grotesque creatures was to congregate.
      Through Earth and Age they roamed, some seeking reaction and chaos, others seeking redemption.
    </p>

    <br>

    <p>
      The Curse of Athena is a burden not easily shed, and wherever tread the Gorgons, so tread the
      Curse: the heinous Curse. Though repelled naturally by the white willow branch, the Ten 
      Hundred and Forty spawn of Medusa collected themselves everywhere that the willow was not.
    </p>

    <br>

    <p>The wayward adventurer beware... The holy pilgrim make haste...</p>
    
    <br>

    <p>The Gorgons are many...</p>

    <br>

    <small class="text-gray-500">-<a href="https://twitter.com/chronicdispo" target="_blank">Chronic Dispositions</a>, 4th century BCE</small>
  </div>
</template>

<script>
export default {
  components: {}
}
</script>